import { AccordionDS, ButtonDS, InputDS } from './../../components'
import {
    JotaMaterialClose as CloseIcon,
    tokens
} from '@JOTAJornalismo/jota-design-system'

const { colors } = tokens

import Link from 'next/link'
import {
    MenuHamburguerComponent,
    HeaderHamburguer,
    InputContainerHamburguer,
    ProItemsContainerHamburguer,
    ButtonsContainerHamburguer,
    SubMenuHamburguer,
    SubMenuHamburguerContainer,
    ButtonContainer
} from './Header.styled'
import { FC, useEffect, useState } from 'react'

import { LogoJotaHeader, showHideItem } from './Header.utils.components'
import { useDispatch } from 'react-redux'

import { useRouter } from 'next/router'
import { sidebarToogle } from '../../../api/ducks/ui'
import { AuthState, AppDispatch } from '../../../api/types/auth'
import { MenuItem } from '../../interfaces'
import { AUTH_CONSTANT, HEADER_CONSTANT } from '../../utils/constants'
import { hamburguerMenuProItems } from '../../utils/fake_api'
import useScrollPosition from '@App/libs/hooks/useScroll'
import { useSearchStore } from '@App/zustand/store/store'
import { TYPE_OF_VERTICAL } from '@App/libs/utils/constants/pro'
import { itemsMap } from './EditorialsComponent'
import { normalizeUrl } from '@App/libs/utils/geral'

interface HamburguerI {
    auth?: AuthState
    wpMenu?: MenuItem[]
}

const HamburguerComponent: FC<HamburguerI> = ({
    auth,
    wpMenu
}): React.JSX.Element => {
    const changeAccordionType = (item: {
        id: number
        label: string
        hasSubcategory: boolean
        level: 'first' | 'second' | 'third'
    }) => {
        return item.level
    }

    const dispatch = useDispatch<AppDispatch>()
    const scrollPosition = useScrollPosition()
    const router = useRouter()
    const [wpMenuState, setWpMenuState] = useState<MenuItem[]>()
    const [menuHeight, setMenuHeight] = useState<string>('auto')
    const { inputValue, setInputValue } = useSearchStore()

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setMenuHeight(window.innerHeight - 20 + 'px')
        }
        window.addEventListener('resize', () =>
            setMenuHeight(window.innerHeight - 20 + 'px')
        )

        const routerParams = (router?.query?.term as string)?.split('&')
        if (routerParams) setInputValue(routerParams[0])
    }, [router?.query?.term, setInputValue])

    useEffect(() => {
        /**
         * Prepare menu to adapt to interface MenuHI
         */
        if (wpMenu) {
            const menuEmpty: MenuItem[] = Object.entries(wpMenu).map(
                (item) => item[1]
            )
            setWpMenuState(menuEmpty)
        }
    }, [wpMenu])

    const getURLbyTitle = (title: string) => {
        if (title.split(' ').length > 3) {
            return title
                .split(' ')[0]
                .replace(':', '')
                .replace(/ /g, '-')
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
        }

        return title
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ /g, '-')
    }

    const hasPaddingLeft = (name: string) => {
        if (name === 'Poder') return '16px'
        return 0
    }

    /**
     * Make SubMenu Contentby Vertical
     * @param {TYPE_OF_VERTICAL} title
     * @return {JSX.Element[] | null}
     */
    const makeSubMenuByVertical = (title: TYPE_OF_VERTICAL) => {
        const items = itemsMap[title]

        if (!items) return null

        return (
            <div style={{ paddingLeft: '16px' }}>
                {items.map((item: any) => {
                    return (
                        <AccordionDS
                            id={`${item.id}`}
                            key={item.id}
                            label={item.text}
                            type={'second'}
                            onClick={() => {
                                window.open(item.url, '_self')
                                return {}
                            }}
                            LeftIcon={item.leftIcon}
                        />
                    )
                })}
            </div>
        )
    }

    /**
     * Return Wordpress Menu
     * @return {gqMenuItem[]} wordpress menu
     */
    const getWPMenu = () => {
        // const itemMenu: any[] = []

        return (
            wpMenuState &&
            wpMenuState.length > 0 &&
            wpMenuState.map((menuItem) => {
                if (
                    (menuItem?.children && menuItem.children.length > 0) ||
                    menuItem.title === 'Tributos' ||
                    menuItem.title === 'Saúde' ||
                    menuItem.title === 'Trabalhista'
                ) {
                    return (
                        <SubMenuHamburguer key={menuItem.ID}>
                            <AccordionDS
                                key={menuItem.ID}
                                id=''
                                label={menuItem.title}
                                type='first'
                                hasBorder={true}
                                RightIcon={false}
                                onClick={() => {
                                    window.open(
                                        `/${getURLbyTitle(menuItem.title)}`,
                                        '_self'
                                    )

                                    showHideItem(String(menuItem.ID), 'menu')
                                    return {}
                                }}
                            />

                            <SubMenuHamburguerContainer
                                id={`menu_${menuItem.ID}`}
                            >
                                {(menuItem.title === 'Poder' ||
                                    menuItem.title === 'Tributos' ||
                                    menuItem.title === 'Saúde' ||
                                    menuItem.title === 'Trabalhista') && (
                                    <AccordionDS
                                        id={'exclusivo'}
                                        key={'exclusivo'}
                                        label={'exclusivo'}
                                        type={'second'}
                                        isSection
                                        isPro
                                    />
                                )}

                                {makeSubMenuByVertical(
                                    menuItem.title as TYPE_OF_VERTICAL
                                )}

                                {menuItem.title === 'Poder' && (
                                    <AccordionDS
                                        id={'editorias-poder'}
                                        key={'editorias-poder'}
                                        label={'editorias'}
                                        type={'second'}
                                        isSection
                                    />
                                )}

                                {menuItem?.children?.map((subitem) => {
                                    return (
                                        <div
                                            key={subitem.title}
                                            style={{
                                                paddingLeft: hasPaddingLeft(
                                                    menuItem.title
                                                )
                                            }}
                                        >
                                            <AccordionDS
                                                key={subitem.ID}
                                                id=''
                                                label={subitem.title}
                                                type='second'
                                                onClick={() => {
                                                    if (
                                                        subitem.title !==
                                                        'Colunas'
                                                    ) {
                                                        window.open(
                                                            `${normalizeUrl(subitem.url)}`,
                                                            '_self'
                                                        )
                                                    }
                                                    return {}
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                                <ButtonContainer>
                                    <ButtonDS
                                        label='Todos os conteúdos'
                                        type='filled'
                                        size='small'
                                        color='white'
                                        LastIcon='jotaMaterialArrow'
                                        insideType='right'
                                        fillIcone='jotaBlack'
                                        onClick={() => {
                                            window.open(
                                                `/${getURLbyTitle(
                                                    menuItem.title
                                                )}`,
                                                '_self'
                                            )
                                            showHideItem(
                                                String(menuItem.ID),
                                                'menu'
                                            )
                                            return {}
                                        }}
                                    />
                                </ButtonContainer>
                            </SubMenuHamburguerContainer>
                        </SubMenuHamburguer>
                    )
                } else {
                    return (
                        <AccordionDS
                            key={menuItem.ID}
                            id=''
                            label={menuItem.title}
                            type='first'
                            hasBorder={true}
                            onClick={() => {
                                if (
                                    menuItem.title !== 'Estúdio JOTA' &&
                                    menuItem.title !== 'Newsletters'
                                ) {
                                    window.open(menuItem.url, '_self')
                                } else if (menuItem.title === 'Estúdio JOTA') {
                                    window.open(
                                        `https://conteudo.jota.info/estudio-jota?utm_source=rd_station&utm_medium=site&" +
                                        "utm_campaign=banner_header_topo_home_estudio_jota&utm_id=header_estudio_jota&utm_term=lp_estudio_jota&utm_term=header_topo_home_estudio_jota`
                                    )
                                } else if (menuItem.title === 'Newsletters') {
                                    window.open(
                                        `${process.env.NEXT_PUBLIC_JOTAINFO}/newsletter-jota`
                                    )
                                }
                                return {}
                            }}
                        />
                    )
                }
            })
        )
    }

    /**
     * Return Backoffice Menu
     * @param {boolean} show
     * @return {gqMenuItem[]} backoffice menu
     */
    const getBOMenu = () => {
        if (auth?.authState === AUTH_CONSTANT.AUTH_STATE.USER_LOGGED) {
            return (
                <ProItemsContainerHamburguer>
                    {hamburguerMenuProItems.length > 0 &&
                        hamburguerMenuProItems.map((item) => (
                            <AccordionDS
                                key={item.id}
                                id=''
                                label={item.label}
                                type={changeAccordionType(item)}
                                hasBorder={item.level !== 'third'}
                                RightIcon={item.hasSubcategory || item.icon}
                                LeftIcon={item.LeftIcon}
                                isPro={item.isPro}
                                isNew={item.isNew}
                            />
                        ))}
                </ProItemsContainerHamburguer>
            )
        }
    }

    return (
        <MenuHamburguerComponent
            height={menuHeight}
            scrollPosition={scrollPosition}
            data-testid='menu-hamburguer'
        >
            <HeaderHamburguer>
                <Link href='/'>
                    <LogoJotaHeader width={86} height={32} />
                </Link>
                <CloseIcon
                    fill={colors.gray.jotaGrayDarker}
                    cursor='pointer'
                    onClick={() => dispatch(sidebarToogle(false))}
                />
            </HeaderHamburguer>

            <InputContainerHamburguer>
                <InputDS
                    RightIcon='jotaMaterialSearch'
                    placeholder={HEADER_CONSTANT.search.input}
                    value={router.pathname !== '/' ? inputValue : ''}
                    onChange={(e: any) => setInputValue(e.target.value)}
                    onKeyDown={(e: any) => {
                        if (
                            e.target.value &&
                            e.which === 13 &&
                            e?.target.value.length >= 2 &&
                            e?.target.value.length <= 200
                        ) {
                            window.location.href = `/busca/${encodeURIComponent(
                                inputValue
                            )}`
                        }
                    }}
                    onRightIconClick={() => {
                        if (
                            inputValue &&
                            inputValue.length >= 2 &&
                            inputValue.length <= 200
                        ) {
                            window.location.href = `/busca/${encodeURIComponent(
                                inputValue
                            )}`
                        }
                    }}
                    showClearText
                />
            </InputContainerHamburguer>

            <AccordionDS
                id=''
                label='Início'
                type={'first'}
                hasBorder
                LeftIcon='jotaMaterialHome'
            />

            {getBOMenu()}
            {getWPMenu()}

            {auth?.authState === AUTH_CONSTANT.AUTH_STATE.USER_NOT_LOGGED && (
                <ButtonsContainerHamburguer>
                    <Link href=''>
                        <ButtonDS
                            label='Conheça o JOTA PRO '
                            size='small'
                            color='black'
                            type='filled'
                            width='100%'
                        />
                    </Link>
                    <Link href=''>
                        <ButtonDS
                            label='Newsletter grátis'
                            size='small'
                            width='100%'
                            type='outlined'
                        />
                    </Link>
                </ButtonsContainerHamburguer>
            )}
        </MenuHamburguerComponent>
    )
}

export default HamburguerComponent
